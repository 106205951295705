import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Link } from "react-scroll";
import interpolate from "interpolate-range";

import sipka from "../images/sipka.svg";
import sipkaBlack from "../images/sipka-black.svg";
import Hero from "../components/Hero/hero";
import HeroAlt from "../components/Hero/heroAlt";
import SideImage from "../components/Section/sideImage";
import BgImage from "../components/Section/bgImage";
import ImageBig from "../components/Section/imageBig";
import ImageSmall from "../components/Section/imageSmall";
import Image from "../components/Section/image";
import Centered from "../components/Section/centered";
import CenteredSmall from "../components/Section/centeredSmall";
import Html from "../components/Section/html";
import Card from "../components/Section/card";

const Cleaner = () => {
  return <div className="full-width" />;
};
const PartialContent = (props) => {
  const _page = props.graphCmsPage;
  const _blocks = [];

  _blocks.push(<Cleaner key={"partial_cleaner"} />);
  _page.partial.forEach((block) => {
    if (block.slug === "block-hero") {
      _blocks.push(<Hero key={block.id} {...block} id={block.slug} />);
    } else if (block.slug === "block-agility") {
      _blocks.push(<HeroAlt key={block.id} {...block} id={block.slug} />);
    } else {
      switch (block.blockType) {
        case "CARD_1":
          block.cleanerBefore &&
            _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(
            <Card key={block.id} {...block} id={block.id} flex={1} />
          );
          break;
        case "CARD_2":
          block.cleanerBefore &&
            _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(
            <Card key={block.id} {...block} id={block.id} flex={2} />
          );
          break;
        case "CARD_3":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(
            <Card key={block.id} {...block} id={block.id} flex={3} />
          );
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "HTML":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<Html key={block.id} {...block} id={block.id} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "IMAGE":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<Image key={block.id} {...block} id={block.id} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "IMAGE_BIG":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<ImageBig key={block.id} {...block} id={block.id} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "IMAGE_SMALL":
          _blocks.push(<ImageSmall key={block.id} {...block} id={block.id} />);
          break;
        case "CENTERED":
          _blocks.push(<Cleaner key={block.id + "_cleaner_before"} />);
          _blocks.push(<Centered key={block.id} {...block} id={block.id} />);
          _blocks.push(<Cleaner key={block.id + "_cleaner_after"} />);
          break;
        case "CENTERED_SMALL":
          _blocks.push(
            <CenteredSmall key={block.id} {...block} id={block.id} />
          );
          break;
        case "BGIMAGE":
          _blocks.push(<BgImage key={block.id} {...block} id={block.id} />);
          break;
        case "SIDEIMAGE":
        default:
          _blocks.push(<SideImage key={block.id} {...block} id={block.id} />);
          break;
      }
    }
  });
  return _blocks;
};

const Page = ({ data }) => {
  const _page = data.graphCmsPage;
  const _illustr = _page.illustr
    ? getImage(_page.illustr.localFile.childImageSharp)
    : null;

  const bgImage = convertToBgImage(_illustr);
  const paralaxRef = useRef();
  useEffect(() => {
    if (bgImage != null) {
      window.addEventListener("scroll", scrollHandler);
      scrollHandler();
      return () => window.removeEventListener("scroll", scrollHandler);
    }
  }, []);
  const scrollHandler = () => {
    const offsetMin = paralaxRef.current.selfRef.offsetTop;
    const offsetMax =
      paralaxRef.current.selfRef.offsetTop +
      paralaxRef.current.selfRef.offsetHeight;

    const config = {
      inputRange: [
        offsetMin - paralaxRef.current.selfRef.offsetHeight * 0.5,
        offsetMax + paralaxRef.current.selfRef.offsetHeight * 0.5,
      ],
      outputRange: [0, paralaxRef.current.selfRef.offsetHeight * 2],
      clamp: true,
    };
    const inter = interpolate(config);

    if (
      window.pageYOffset + window.innerHeight >= offsetMin &&
      window.pageYOffset < offsetMax
    )
      paralaxRef.current.selfRef.style.perspectiveOrigin =
        "center " +
        (inter(window.pageYOffset) - paralaxRef.current.selfRef.offsetHeight) +
        "px";
  };
  return (
    <div className="flex page">
      {bgImage && (
        <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
          className="lead-illustr paralax"
          ref={paralaxRef}
        >
          <h1>{_page.title}</h1>
          <p>{_page.description}</p>
          {_page.hideArrow !== true && (
            <Link
              to="mainContent"
              smooth={true}
              duration={500}
              className="link"
              href={"#mainContent"}
            >
              <img className="sipka" src={sipka} />
            </Link>
          )}
        </BackgroundImage>
      )}
      {bgImage === null && _page.hideArrow !== true && (
        <div className="lead-text centered">
          {_page.leadline && <h3>{_page.leadline}</h3>}
          <h1>{_page.title}</h1>
          {_page.description && <p>{_page.description}</p>}

          <Link
            to="mainContent"
            smooth={true}
            duration={500}
            className="link"
            href={"#mainContent"}
          >
            <img className="sipka" src={sipkaBlack} />
          </Link>
        </div>
      )}
      <div id="mainContent"></div>
      {_page.body && (
        <SectionWrapper className="section html">
          <div dangerouslySetInnerHTML={{ __html: _page.body.html }}></div>
        </SectionWrapper>
      )}
      <PartialContent {...data} />
    </div>
  );
};

export const query = graphql`
  query($slug: String) {
    graphCmsPage(slug: { eq: $slug }) {
      id
      body {
        html
      }
      illustr {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      leadLine
      hideArrow
      partial {
        ... on GraphCMS_Page {
          id
          category
          slug
          title
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          blockType
          description
          icon {
            localFile {
              publicURL
            }
          }
          colors {
            hex
          }
          buttonText
          leadLine
          locale
        }
        ... on GraphCMS_Job {
          id
          category
          colors {
            hex
          }
          slug
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
          blockType
          buttonText
          description
          icon {
            localFile {
              publicURL
            }
          }
          salary
          place
          kind
          locale
        }
        ... on GraphCMS_Block {
          id
          blockType
          buttonText
          category
          colors {
            hex
          }
          description
          icon {
            localFile {
              publicURL
            }
          }
          download
          illustr {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          link
          leadLine
          slug
          title
          locale
          markup {
            html
          }
          cleanerBefore
        }
      }
      slug
      title
      category
      description
      icon {
        gatsbyImageData
      }
    }
  }
`;
const SectionWrapper = styled.section`
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
    p {
      display: inline-flex;
      padding: 4px;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      border: 4px solid #eb2252;
      margin-right: 10px;
      font-size: 30px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: #eb2252;
      font-weight: bold;
      text-align: center;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 25px;
    margin: 30px 0;
  }
`;

export default Page;
